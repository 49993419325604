import instructorGeorge from './instructor-george.jpg';
import instructorMike from './instructor-mike-verdugo.jpg';
import instructorJohnny from './instructor-johnny.jpg';

export default function About() {
    return (
        <div>
            <h2 className="display-1">About</h2>

            <h3>Recreation and Parks Judo Program</h3>
            <p>
                USA Judo and the City of Los Angeles Department of Recreation and Parks launched their 
                first “Homegrown” Pilot Judo Program. The program aims to introduce Los Angeles area 
                youth to the benefits associated with judo, both on and off the mat. The program also 
                aims to create a pipeline of potential “Homegrown” Olympians or Paralympians over the 
                next nine years. With Los Angeles hosting the 2028 Olympic and Paralympic Games, this 
                partnership will jump-start the hopes of developing athletes who may represent Team USA 
                in their hometown and on the international stage. 
            </p>
            <ul className="list-unstyled">
                <li>
                    <a href="https://www.teamusa.org/usa-judo/features/2019/september/13/usa-judo-and-city-of-los-angeles-department-of-recreation-and-parks--launch-pilot-judo-program">Recreation and Parks Launch Pilot Judo Program (september 13, 2019)</a>
                </li>
                <li>
                    <a href="https://www.foxla.com/news/la-city-council-accepts-7-7-million-from-olympics-committee-for-youth-sports">LA City Council accepts $7.7 million from Olympics committee for youth sports (June 26, 2021)</a>
                </li>
            </ul>
            <h3 id="instructors" className="scroll-top">Instructors</h3>

            <div className="row">
                    <div className="col-sm-12">
                        <div className="card text-white bg-dark " >
                            <div className="card-body">
                                <img src={instructorGeorge} className="card-img-top" alt="Sensei George and Sensei Marlon" />
                                <h4 className="card-title">George Barba </h4><h5 className="card-subtitle mb-2 text-muted">Shodan (1st Degree)</h5>
                                <p className="card-text">
                                    Sensei George has been training judo since 2012 under the tutelage of Sensei Mike Verdugo (Rokudan - 6th Degree) of <a href="https://www.pasadenajudo.com/" title="Pasadena Kodokan Judo Club">Pasadena Kodokan Judo</a>.
                                    As part of the competition team of Pasadena Kodokan Judo, he has competed in various State and National tournaments. In 2018 George was awarded the rank of Shodan (1st Degree), and has continued his pursuit of martial
                                    arts in both Judo and BJJ (Brazilian Jiu-Jitsu).
                                </p>
                            </div>
                        </div>
                    </div>
                    
                
                    <div className="col-sm-12 card-group">
                        <div className="card text-white bg-dark m-2">
                            <div className="card-body">
                                <img src={instructorMike} className="card-img-top" alt="Sensei Mike Verdugo" />
                                <h4 className="card-title">Mike Verdugo </h4><h5 className="card-subtitle mb-2 text-muted">Rokudan (6th Degree)</h5>
                                <p className="card-text">
                                    Sensei Mike oversees USA Judo's Play LA Program featured in 16 LA Recreation Centers and is a member of USA Judo's Police Professionals & PAL (P3) Program.
                                </p>
                            </div>
                        </div>
                    
                    
                        <div className="card text-white bg-dark m-2">
                            <div className="card-body">
                                <img src={instructorJohnny} className="card-img-top" alt="Sensei Johnny Hoong" />
                                <h4 className="card-title">Johnny Hoong</h4><h5 className="card-subtitle mb-2 text-muted">Shodan (1st Degree)</h5>
                                <p className="card-text">
                                    Sensei Johnny is currently Judo Coordinator for LA Parks and Recreation Judo program and currently teaches at 4 separate locations.
                                </p>
                            </div>
                        </div>
                    </div>
                    
                
                
            </div>
           
            
           
          </div>

          
    );
}