import logo from './highlandpark-judo-logo.svg';
import Calendar from './Calendar.js';
import About from './About.js';
import Navigation from './Navigation.js';
import './App.css';

function App() {
  return (
    <div className="App container">
      <Navigation />
      <header className="App-header">
        
        <div className="row">
          <div className="col-12">
              <img src={logo} className="App-logo" alt="logo" />
            <h1>Highland Park Judo</h1>
            <p>
                Judo fundamentals course for children ages 6-15 through Los Angeles Recreation and Parks at Highland Park Recreation Center. <br/><br/>
                
            </p>
            <p>
                <strong>Tuesdays and Thursdays 6pm - 7pm</strong> <br/>
                <strong><a href="https://www.laparks.org/reccenter/highland">Highland Park Recreation Center</a></strong><br/>
                6150 Piedmont Ave.<br/>
                Los Angeles, CA 90042<br/>
            </p>
            <p>
              Register for classes online at <a href="https://www.laparks.org/reccenter/highland" target="_blank" title="HIGHLAND PARK RECREATION CENTER | City of Los Angeles Department of Recreation and Parks">laparks.org</a> or come in person between 10am - 9pm.<br/>
              <a className="btn btn-primary btn-lg" href="https://www.laparks.org/reccenter/highland" target="_blank" title="HIGHLAND PARK RECREATION CENTER | City of Los Angeles Department of Recreation and Parks">Register Online</a>
            </p>
            <div className="iframe-container google-maps">
              <iframe className="responsive-iframe"width="600" height="450" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.29036232846!2d-118.18791010000001!3d34.1133155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c40dfeaf2e21%3A0x40f7bc97b27aea1e!2sHighland%20Park%20Recreation%20Center!5e0!3m2!1sen!2sus!4v1686578717689!5m2!1sen!2sus"  allowFullScreen=""  referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
        
        
      </header>
      <div className="row my-5 scroll-top" id="calendar">
        <div className="col">
          <Calendar/>
        </div>
      </div>
      <div className="row scroll-top" id="about">
        <div className="col">
          <About />
        </div>
      </div>
      
    </div>
  );
}

export default App;
