export default function Calendar() {
    return (
      <div className="calendar">
        <h2 className="display-1">Calendar</h2>
        <div className="gCalendar">
            <div className="iframe-container">
              <iframe title="gCalendar" id="gCalendar" className="responsive-iframe" src="https://calendar.google.com/calendar/embed?wkst=1&ctz=America%2FLos_Angeles&src=Z2VvcmdlQHVuZGVycmF0ZWRicmlsbGlhbmNlLmNvbQ&color=%23FFFFFF&bgcolor=%23FFFFFF" style={{backgroundColor:'#FFF'}}  frameBorder="0" scrolling="no"></iframe>
            </div>
          </div>
      </div>
        
    );
}